/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';
// import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery.js';
// import Burger from '../../../../../Assets/Shared/FindRestaurant/burger.svg';
import { ReactComponent as PackageGreen } from '../../../../../Assets/Shared/FindRestaurant/packagegreen.svg';
import { ReactComponent as PackageWhite } from '../../../../../Assets/Shared/FindRestaurant/packagewhite.svg';
import { ReactComponent as MotorWhite } from '../../../../../Assets/Shared/FindRestaurant/motorwhite.svg';
import { ReactComponent as MotorGreen } from '../../../../../Assets/Shared/FindRestaurant/motorgreen.svg';
import {
    CardWrapper,
    ContentWrapper,
    DownContent,
    UpTextStyle,
    UpTextWrapper,
    IconWrapper,
    ShopNameStyle,
    LogoStyle,
    RatingStyle,
    RatingTextStyle,
    LocationTextStyle,
    BottomSectionStyle,
    BottomContainerStyle,
    BottomTextStyle,
    DiscountTextStyle,
    PriceTextStyle,
    MiddleSectionStyle,
    EmptyDiv,
} from './styles';
// import Logo from '../../../../../Assets/Shared/FindRestaurant/logo.svg';
import Star from '../../../../../Assets/Shared/FindRestaurant/star.svg';
import Locate from '../../../../../Assets/Shared/FindRestaurant/locate.svg';
import Timer from '../../../../../Assets/Shared/FindRestaurant/time.svg';
import { Text } from '../../../../components/Typography/styles';
import { getDistanceFromLatLonInKm } from '../../../../../helpers/index';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Customers = ({ data, coordinates }) => {
    const history = useHistory();
    let distance = 0;
    const [dataPackage, setDataPackage] = useState('');

    useEffect(() => {
        const today = new Date();
        const todayFormat = today.toISOString();
        setDataPackage(data?.calendar?.filter((e) => e?.start_date.substring(0, 10) === todayFormat.substring(0, 10)));
        // setIsOpen(dataPackage[0]?.is_active);
        // eslint-disable-next-line no-console
    }, [data]);

    if (data && coordinates) {
        distance = getDistanceFromLatLonInKm(coordinates.lat, coordinates.lng, data.latitude, data.longitude);
    }

    if (data && data.package_settings) {
        return (
            <ContentWrapper
                onClick={() => {
                    // localStorage.setItem('cooordinatesLat', coordinates.lat);
                    // localStorage.setItem('cooordinatesLng', coordinates.lng);
                    // localStorage.setItem('dataLat', data.latitude);
                    // localStorage.setItem('dataLng', data.longitude);
                    history.push(`/restoran-detay/${data.id}`);
                }}
                style={
                    !dataPackage[0]?.is_active && {
                        filter: `grayscale(100%)`,
                    }
                }>
                <DownContent>
                    <CardWrapper backgroundImage={data.background}>
                        <UpTextWrapper>
                            {data?.package_settings && data?.calendar.length > 0 ? (
                                <UpTextStyle style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 500 }}>
                                    {dataPackage[0]?.box_count} paket
                                </UpTextStyle>
                            ) : (
                                <UpTextStyle style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 500 }}>0 paket</UpTextStyle>
                            )}
                        </UpTextWrapper>
                        {data?.package_settings?.delivery_type === '1' ? (
                            <IconWrapper>
                                <Row style={{ marginRight: 6 }}>
                                    <PackageGreen />
                                </Row>
                                <Row>
                                    <MotorWhite />
                                </Row>
                            </IconWrapper>
                        ) : (
                            <IconWrapper>
                                <Row style={{ marginRight: 6 }}>
                                    {data?.package_settings?.delivery_type === '2' ? <PackageWhite /> : <PackageGreen />}
                                </Row>
                                <Row>
                                    <MotorGreen />
                                </Row>
                            </IconWrapper>
                        )}
                    </CardWrapper>
                    <Col>
                        <MiddleSectionStyle>
                            <Col>
                                <LogoStyle backgroundImage={data?.photo} />
                                <RatingStyle>
                                    <Row align="middle">
                                        <img src={Star} width="16px" alt="star" />
                                        <RatingTextStyle style={{ fontWeight: '300', fontSize: 13 }}>
                                            {data?.review.length >= 5 ? <>{Number(data.avg_review).toFixed(2)}</> : <>5</>}
                                        </RatingTextStyle>
                                    </Row>
                                </RatingStyle>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column', maxWidth: '55%' }}>
                                <ShopNameStyle style={{ fontSize: 16, fontWeight: '500' }}>{data.name}</ShopNameStyle>
                                <LocationTextStyle style={{ fontSize: 13, fontWeight: '300' }}>{data.address.split(' ')[0]}</LocationTextStyle>
                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                <EmptyDiv />
                                <Row>
                                    <img src={Locate} alt="" width="16px" />
                                    <Text style={{ marginLeft: 6, color: '#F38262', fontSize: 14, fontWeight: '600' }}>{distance.toFixed(2)} km</Text>
                                </Row>
                            </Col>
                        </MiddleSectionStyle>
                        <BottomSectionStyle>
                            <BottomContainerStyle>
                                <img src={Timer} alt="clock" />
                                {dataPackage ? (
                                    <BottomTextStyle style={{ fontWeight: '500', fontSize: 14, color: '#FFBC41' }}>
                                        {dataPackage[0]?.start_date.substring(11, 16)}-{dataPackage[0]?.end_date.substring(11, 16)}
                                    </BottomTextStyle>
                                ) : (
                                    <BottomTextStyle style={{ fontWeight: '500', fontSize: 14, color: '#FFBC41' }}>-</BottomTextStyle>
                                )}
                            </BottomContainerStyle>
                            <Row>
                                <DiscountTextStyle>
                                    <Text style={{ fontSize: 14, fontWeight: 500, color: '#BCBCBC', textDecoration: 'line-through' }}>
                                        {data.package_settings.min_order_price} TL
                                    </Text>
                                    <PriceTextStyle>
                                        <Text style={{ color: '#8FD89F', fontSize: 16, fontWeight: '600' }}>
                                            {data.package_settings.min_discounted_order_price} TL
                                        </Text>
                                    </PriceTextStyle>
                                </DiscountTextStyle>
                            </Row>
                        </BottomSectionStyle>
                    </Col>
                </DownContent>
            </ContentWrapper>
        );
    }
    return null;
};

export default Customers;
