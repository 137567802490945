export default function getSuperAppVersion() {
    if (window?.flutter_inappwebview) {
        //  alert('super app den giris yapildi');
        return true;
    }
    return false;
}

// const HookDoSomething = () => {
//     const [isSuperApp, setSuperApp] = React.useState(false);
//     React.useEffect(() => {
//         console.log('home acildi');
//         const superAppValue = getSuperAppVersion();
//         setSuperApp(superAppValue);
//     }, []);
//     return isSuperApp;
// };

// export default HookDoSomething
