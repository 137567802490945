import { Col } from 'antd';
import styled from 'styled-components';

export const StyledDiv = styled.div`
    min-height: 56px;
    padding: 10px 0 10px 32px;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-top; 25px;
`;

export const DetailsDiv = styled.div`
    min-height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding-right: 70px;
    padding-left: 70px;
    margin-right: 50px;
`;

export const StyledEdit = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
`;
