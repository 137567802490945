/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProfileSectionStyles, UserIconHolder, MenuStyles, CustomButton, CustomPopover } from './styles';
import { Text } from '../../../Typography/styles';
import profileLogo from '../../../../../Assets/Dashboard/profile-logo.png';
import openNotification from '../../../Notification';
import { IsOutsideClick } from '../../../../../helpers';
import notificationAudio from '../../../../../Assets/Dashboard/notificationAudio/notification.mp3';
import Button from '../../../Button';

const Menu = ({ menuItems, basketList }) => {
    const history = useHistory();

    const directToNotifications = () => {
        history.push('/bildirimlerim');
    };

    const WEBSOCKET_ENDPOINT = 'wss://apiprep.dongu.com/websocket';
    const socketConnection = new WebSocket(WEBSOCKET_ENDPOINT);
    const TOKEN = localStorage.getItem('token');

    socketConnection.onmessage = function (e) {
        const data = JSON.parse(e.data);
        if (data.object) {
            let temp = data.object;
            temp = temp?.replaceAll("'", '"');
            temp = temp?.replaceAll('False', 'false');
            temp = JSON.parse(temp);
            if (temp.type === 7) {
                openNotification(temp.description, temp.message.split('order_id')[0], directToNotifications, false);
                new Audio(notificationAudio).play();
            } else {
                openNotification(temp.description, temp.message, directToNotifications, false);
                new Audio(notificationAudio).play();
            }
        }
    };

    socketConnection.onopen = function () {
        console.log('Socket connection is established');
    };

    socketConnection.onclose = function () {
        console.error('Socket connection is closed unexpectedly');
    };

    function subscribe(group, thisToken = null) {
        socketConnection.send(
            JSON.stringify({
                type: 'subscribe',
                token: thisToken,
                content: group,
            })
        );
    }

    function unsubscribe(group) {
        socketConnection.send(
            JSON.stringify({
                type: 'unsubscribe',
                content: group,
            })
        );
    }

    function waitConnection() {
        // if (socketConnection.readyState === WebSocket.OPEN && localStorage.getItem('notifications') === 'true') {
        if (socketConnection.readyState === WebSocket.OPEN) {
            subscribe('notification', TOKEN);
            subscribe('store_orders', TOKEN);
            subscribe('store_notifications', TOKEN);
        } else if (socketConnection.readyState !== WebSocket.CLOSED && socketConnection.readyState !== WebSocket.CLOSING) {
            setTimeout(waitConnection, 500);
            console.log(socketConnection.readyState === WebSocket.OPEN);
        }
    }

    useEffect(() => {
        waitConnection();
        return () => unsubscribe();
    }, []);

    return (
        <MenuStyles>
            {menuItems.map(({ name, route, handler }) => (
                <CustomButton key={name} type="text" onClick={() => (route ? history.push(route) : handler && handler())}>
                    <Text color="smoke" weight="light" size="16px" style={{ marginRight: '1rem' }}>
                        {name}
                    </Text>
                    {name === 'Sepetim' && basketList?.length > 0 && Array.isArray(basketList) && (
                        <Button type="primary">{basketList?.length}</Button>
                    )}
                </CustomButton>
            ))}
        </MenuStyles>
    );
};

const Profile = ({ menuItems, basketList }) => {
    const [showPopover, setShowPopover] = useState(false);
    const togglePopover = () => setShowPopover(!showPopover);
    return (
        <IsOutsideClick
            Component={
                <ProfileSectionStyles align="middle" onClick={togglePopover}>
                    <CustomPopover
                        overlayStyle={{ position: 'fixed' }}
                        overlayInnerStyle={{ borderRadius: 18 }}
                        placement="bottom"
                        visible={showPopover}
                        content={React.cloneElement(<Menu />, { menuItems, basketList })}
                        trigger={['click']}>
                        <UserIconHolder>
                            <img className="cover-image" src={profileLogo} alt="" />
                        </UserIconHolder>
                    </CustomPopover>
                </ProfileSectionStyles>
            }
            handler={() => setShowPopover(false)}
        />
    );
};

export default Profile;
