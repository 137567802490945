/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint import/no-unresolved: off */

import { Row, Col, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import Provider from './Provider/index';
import LoginCardStyles, { Title, Text, Form, ContinueButton, SocialDiv, Checkbox } from './styles';
import FloatingLabel from '../../../../components/TextInput/textInput';
import { postUser } from '../../../../../services/Redux/Shared/register/api';
import CustomErrorMessage from '../../../../components/CustomErrorMessage';
import SelectCountry from './SelectCountry';

const defaultPasswordValidation = {
    min: false,
    uppercase: false,
    number: false,
};

const passwordValidationMessages = {
    min: '8 karakter',
    uppercase: '1 büyük harf',
    number: '1 rakam',
};

const SignUpCard = () => {
    const [country, setCountry] = useState({ alpha2: 'TR', code: '+90', name: 'Türkiye' });
    const [errorMessage, setErrorMessage] = useState(null);
    const [passwordValidation, setPasswordValidation] = useState({ ...defaultPasswordValidation });
    const dispatch = useDispatch();
    const history = useHistory();

    const FormValidationSchema = Yup.object().shape({
        full_name: Yup.string().required('Lütfen adınızı ve soyadınızı giriniz'),
        email: Yup.string().required('Lütfen e-posta adresinizi giriniz').email('Lütfen formata uygun bir e-posta adresi giriniz'),
        phone_number: Yup.string().required('Lütfen telefon numaranızı giriniz'),
        password: Yup.string().required('Lütfen bir şifre giriniz'),
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const TooltipContent = () => (
        <div>
            {Object.keys(passwordValidation).map((key, i) => (
                <Row key={i}>
                    <Text color={passwordValidation[key] ? 'green' : 'black'} size="10px">
                        {passwordValidation[key] ? <CheckOutlined /> : <InfoCircleOutlined />} {passwordValidationMessages[key]}
                    </Text>
                </Row>
            ))}
        </div>
    );

    const handlePasswordChange = (e, setFieldValue) => {
        const { value } = e.target;
        setFieldValue('password', value);
        const passwordValidation = {
            min: value.length >= 8,
            uppercase: /[A-Z]/.test(value),
            number: /\d+/.test(value),
        };
        setPasswordValidation(passwordValidation);
    };

    const signup = (values) => {
        const { full_name, phone_number, email, password } = values;
        const [first_name, last_name] = full_name.split(' ');
        const newInfo = {
            first_name,
            last_name,
            email,
            password,
            password2: password,
            phone_number: `${country.code}${phone_number}`,
            groups: ['Customer'],
        };
        dispatch(postUser(newInfo)).catch((error) => setErrorMessage(error));
    };
    return (
        <LoginCardStyles>
            <Title size="title" weight="normal">
                Üye Ol
            </Title>
            <Formik
                initialValues={{
                    full_name: '',
                    email: '',
                    password: '',
                    phone_number: '',
                }}
                onSubmit={signup}
                validationSchema={FormValidationSchema}>
                {({ values, handleChange, handleSubmit, setFieldValue, errors, touched }) => (
                    <Form autoComplete="off" onFinish={handleSubmit}>
                        <Form.Item style={{ marginTop: 50 }} name="number">
                            <Row justify="space-between" align="middle">
                                <Col span={7}>
                                    <SelectCountry country={country} setCountry={setCountry} />
                                </Col>
                                <Col span={16}>
                                    <FloatingLabel
                                        id="phone_number"
                                        name="phone_number"
                                        placeholder="Cep Telefonu"
                                        type="text"
                                        autofill={false}
                                        value={values.phone_number}
                                        onChange={handleChange}
                                        maxLength={10}
                                    />
                                </Col>
                            </Row>
                        </Form.Item>
                        {((errors.phone_number && touched.phone_number) || errorMessage?.phone_number) && (
                            <CustomErrorMessage message={errors.phone_number || errorMessage?.phone_number} />
                        )}
                        <Form.Item name="full_name">
                            <FloatingLabel
                                id="full_name"
                                name="full_name"
                                placeholder="İsim Soyisim"
                                type="text"
                                autofill={false}
                                value={values.full_name}
                                onChange={handleChange}
                            />
                        </Form.Item>
                        {errors.full_name && touched.full_name && <CustomErrorMessage message={errors.full_name} />}
                        <Form.Item name="email">
                            <FloatingLabel
                                id="email"
                                name="email"
                                placeholder="E-Posta"
                                type="email"
                                value={values.email}
                                autoComplete="off"
                                onChange={handleChange}
                            />
                        </Form.Item>
                        {((errors.email && touched.email) || errorMessage?.email) && (
                            <CustomErrorMessage message={errors.email || errorMessage?.email} />
                        )}
                        <Form.Item name="password">
                            <Tooltip color="white" title={TooltipContent}>
                                <FloatingLabel
                                    id="password"
                                    name="password"
                                    placeholder="Şifre"
                                    type="password"
                                    visibility="off"
                                    autoComplete="new-password"
                                    value={values.password}
                                    onChange={(e) => handlePasswordChange(e, setFieldValue)}
                                />
                            </Tooltip>
                        </Form.Item>
                        {((errors.password && touched.password) || errorMessage?.password) && (
                            <CustomErrorMessage message={errors.password || errorMessage?.password} />
                        )}
                        <Row align="middle" style={{ columnGap: '10px' }}>
                            <Col span={2}>
                                <Checkbox />
                            </Col>
                            <Col span={20}>
                                <Text size="11px" color="smoke">
                                    Önemli kampanyalardan haberdar olmak için &nbsp;
                                    <Text size="10px" color="red" style={{ cursor: 'pointer' }} onClick={() => history.push('/cerez-politikasi')}>
                                        Rıza Metni
                                    </Text>
                                    &nbsp; kapsamında elektronik ileti almak istiyorum.
                                </Text>
                            </Col>
                        </Row>
                        <Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ContinueButton htmlType="submit">
                                    <Text color="white" weight="bold">
                                        Üye Ol
                                    </Text>
                                </ContinueButton>
                            </div>
                        </Form.Item>
                        <Row justify="center" style={{ paddingBottom: '20px' }}>
                            <Col span={20} style={{ marginLeft: '20px' }}>
                                <Text size="10px">
                                    Üye olmakla &nbsp;
                                    <Text size="10px" color="red" style={{ cursor: 'pointer' }} onClick={() => history.push('/kullanici-sozlesmesi')}>
                                        Kullanım Koşullarını
                                    </Text>
                                    &nbsp;onaylamış olursunuz. Kişisel verilerinize dair Aydınlatma Metni için&nbsp;
                                    <Text size="10px" color="red" style={{ cursor: 'pointer' }} onClick={() => history.push('/kvkk')}>
                                        tıklayınız.
                                    </Text>
                                </Text>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            <SocialDiv>
                <Provider provider="google" />
                <Provider provider="facebook" />
            </SocialDiv>
        </LoginCardStyles>
    );
};

export default withRouter(SignUpCard);
