import { useEffect, useState } from 'react';
import getSuperAppVersion from '../getSuperAppVersion';

function useSuperApp() {
    const [isSuperApp, setSuperApp] = useState(false);
    useEffect(() => {
        const superAppValue = getSuperAppVersion();
        setSuperApp(superAppValue);
    }, []);
    return isSuperApp;
}

export default useSuperApp;
