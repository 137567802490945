export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_SUCCESS_CUSTOMER = 'AUTH_SUCCESS_CUSTOMER';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';

// FACEBOOK AUTH

export const AUTH_FACEBOOK_START = 'AUTH_FACEBOOK_START';
export const AUTH_FACEBOOK_SUCCESS = 'AUTH_FACEBOOK_SUCCESS';
export const AUTH_FACEBOOK_FAIL = 'AUTH_FACEBOOK_FAIL';

// FACEBOOK CONNECT

export const FACEBOOK_CONNECT_START = 'FACEBOOK_CONNECT_START';
export const FACEBOOK_CONNECT_SUCCESS = 'FACEBOOK_CONNECT_SUCCESS';
export const FACEBOOK_CONNECT_FAIL = 'FACEBOOK_CONNECT_FAIL';

// SOCIAL DISCONNECT

export const SOCIAL_DISCONNECT_START = 'SOCIAL_DISCONNECT_START';
export const SOCIAL_DISCONNECT_SUCCESS = 'SOCIAL_DISCONNECT_SUCCESS';
export const SOCIAL_DISCONNECT_FAIL = 'SOCIAL_DISCONNECT_FAIL';

// KOBIL OPEN ID
export const KOBIL_OPENID_CONNECT_SUCCES = 'KOBIL_OPENID_CONNECT_SUCCES';
export const KOBIL_OPENID_CONNECT_FAIL = 'KOBIL_OPENID_CONNECT_FAIL';
