/* eslint-disable no-restricted-globals */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Col, Row } from 'antd';
import { between } from 'polished';
import StyledFooter, {
    FooterBottomWrapper,
    PaymentImg,
    FooterTopWrapper,
    FooterBadgeWrapper,
    FooterNavItemStyles,
    IconWrapperCircle,
} from './styles';
import googleplayIcon from '../../../../Assets/Shared/Layout/googleplay.svg';
import appstoreIcon from '../../../../Assets/Shared/Layout/appstore.svg';
// import Try from '../../../../Assets/Shared/Layout/try.svg';
import facebook from '../../../../Assets/Shared/Layout/facebook.svg';
import youtube from '../../../../Assets/Shared/Layout/youtube.svg';
import instagram from '../../../../Assets/Shared/Layout/instagram.svg';
import twitter from '../../../../Assets/Shared/Layout/twitter.svg';
import linkedin from '../../../../Assets/Shared/Layout/linkedin.svg';
import iyzico from '../../../../Assets/Shared/common/iyzico.svg';
import { Text } from '../../Typography/styles';
import useResponsive from '../../../../helpers/Hooks/useMediaQuery';
import donguLogo from '../../../../Assets/Shared/Layout/donguLogo.svg';
import getSuperAppVersion from '../../../../helpers/getSuperAppVersion';

const footerData = [
    {
        id: 1,
        title: 'İŞLEYİŞ',
        datas: [
            {
                id: 1,
                name: 'Döngü Nasıl Çalışır?',
                path: '/hakkimizda',
            },
        ],
    },
    {
        id: 2,
        title: 'DÖNGÜ',
        datas: [
            {
                id: 3,
                name: 'Anasayfa',
                path: '/#',
            },
            {
                id: 4,
                name: 'Restoran Bul',
                path: '/restoran-bul',
            },
            {
                id: 5,
                name: 'Gıda İsrafı Hakkında',
                path: '/gida-israfi',
            },
            {
                id: 6,
                name: 'Hakkımızda',
                path: '/hakkimizda',
            },
            {
                id: 7,
                name: 'İşletmeler için Döngü',
                path: '/isletmeler-icin',
            },
        ],
    },
    {
        id: 3,
        title: 'TOPLULUK',
        datas: [
            {
                id: 9,
                name: 'Sıkça Sorulan Sorular',
                path: '/sikca-sorulan-sorular',
            },
            {
                id: 10,
                name: 'Çerez Politikası',
                path: '/cerez-politikasi',
            },
            {
                id: 11,
                name: 'KVKK',
                path: '/kvkk',
            },
            {
                id: 12,
                name: 'Kullanıcı Sözleşmesi',
                path: '/kullanici-sozlesmesi',
            },
        ],
    },
    {
        id: 4,
        title: 'İLETİŞİM',
        datas: [
            {
                id: 17,
                name: 'Bize Ulaşın',
                path: '/destek',
            },
        ],
    },
];

const removeIfEqual = ['/profilim/gecmis-siparislerim', '/profilim/adreslerim', '/favorilerim', '/gida-israfi', '/hakkimizda', '/isletmeler-icin'];

const FooterNavItem = ({ title, datas }) => {
    const history = useHistory();

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <FooterNavItemStyles xs={24} sm={12} md={6}>
            <Row style={{ marginBottom: 10 }}>
                <Text size="description" color="black" weight="bold">
                    {title}
                </Text>
            </Row>
            {datas.map(({ id, name, path }) => (
                <Row key={id} style={{ marginBottom: between('5px', '13px'), cursor: 'pointer' }}>
                    <Text
                        size="description"
                        color="black"
                        weight="medium"
                        onClick={() => {
                            history.push(path);
                            window.scrollTo(0, id === 1 ? windowDimensions.height * 1.3 : 0);
                        }}>
                        {name}
                    </Text>
                </Row>
            ))}
        </FooterNavItemStyles>
    );
};

const validateRemoval = (path) => {
    if (removeIfEqual.includes(path)) return true;
    if (
        path?.split('/')[1] === 'restoran-detay' &&
        (path?.split('/')[3] === 'magaza-bilgisi' || path?.split('/')[3] === 'gida-kategorileri' || path?.split('/')[3] === 'calicma-saatleri')
    )
        return true;
    return false;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const Footer = () => {
    const { xs } = useResponsive();
    const history = useHistory();
    const onClickLogo = () => {
        history.push('/');
        window.scrollTo(0, 0);
    };

    return (
        <>
            {!getSuperAppVersion() ? (
                <StyledFooter style={{ display: validateRemoval(location.pathname) ? 'none' : 'block' }}>
                    <Col className="height-full">
                        <FooterTopWrapper>
                            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
                                <FooterBadgeWrapper>
                                    <Col
                                        style={{ justifyContent: 'space-between' }}
                                        xs={{ span: 24 }}
                                        md={{ span: 13 }}
                                        lg={{ span: 10 }}
                                        xl={{ span: 24 }}
                                        xxl={{ span: 24 }}
                                        className="spacebetween">
                                        <Row justify="center" style={{ marginBottom: 40 }} onClick={() => onClickLogo()}>
                                            <img src={donguLogo} alt="dongu-logo" className="footer-logo" />
                                        </Row>

                                        <div>
                                            <Row justify="space-between" style={{ marginBottom: 30 }}>
                                                <Col span={10}>
                                                    <a
                                                        href="https://apps.apple.com/tr/app/d%C3%B6ng%C3%BC/id1581616839"
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        <img src={appstoreIcon} alt="appstore-icon" className="appstore-logo" />
                                                    </a>
                                                </Col>
                                                <Col span={10}>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=com.westerops.dongu_mobile"
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        <img src={googleplayIcon} alt="googleplay-icon" className="googleplay-logo" />
                                                    </a>
                                                </Col>
                                            </Row>
                                            <Row align="middle" justify="space-between" style={{ marginBottom: 20 }}>
                                                <IconWrapperCircle target="_blank" href="https://www.facebook.com/dongu.tr">
                                                    <img src={facebook} alt="facebook-icon" />
                                                </IconWrapperCircle>
                                                <IconWrapperCircle target="_blank" href="https://twitter.com/dongutr">
                                                    <img src={twitter} alt="twitter-icon" />
                                                </IconWrapperCircle>
                                                <IconWrapperCircle target="_blank" href="https://www.instagram.com/dongu.tr/">
                                                    <img src={instagram} alt="instagram-icon" />
                                                </IconWrapperCircle>
                                                <IconWrapperCircle target="_blank" href="https://www.linkedin.com/company/dongutr/">
                                                    <img src={linkedin} alt="linkedin-icon" />
                                                </IconWrapperCircle>
                                                <IconWrapperCircle target="_blank" href="https://www.youtube.com">
                                                    <img src={youtube} alt="youtube-icon" />
                                                </IconWrapperCircle>
                                                <Col xs={0} sm={12}>
                                                    <PaymentImg src={iyzico} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </FooterBadgeWrapper>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={16} xxl={16}>
                                <Row justify="space-between" gutter={[{ xs: 44, sm: 44, md: 44, lg: 44, xl: 44 }, 0]}>
                                    {footerData?.map(({ id, title, datas }) => (
                                        <FooterNavItem key={id} itemId={id} title={title} datas={datas} />
                                    ))}
                                </Row>
                            </Col>
                        </FooterTopWrapper>
                        <FooterBottomWrapper style={{ display: 'flex' }}>
                            {/* <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6} className={(xs || sm || md || lg) && 'margin-top'}>
                                <Row
                                    align="middle"
                                    justify={xs || sm || md || lg ? 'space-between' : 'end'}
                                    style={{ marginBottom: xs ? '1rem' : '0' }}>
                                    <Text style={{ marginRight: sm ? '0px' : '48px' }} size="smallTitle" color="#000" weight="semi-bold">
                                        Türkçe (TR)
                                    </Text>
                                    <Text size="smallTitle" color="#000" weight="semi-bold">
                                        <img src={Try} alt="TRY" style={{ marginRight: '5px' }} />
                                        TRY
                                    </Text>
                                </Row>
                            </Col> */}

                            <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                                <Row
                                    align="middle"
                                    justify={xs ? 'start' : 'center'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', sm: 'row' },
                                        columnGap: '10px',
                                    }}>
                                    <Text size="8px" color="#000" weight="medium">
                                        © 2021 Döngü Tüm Hakları Saklıdır.
                                    </Text>
                                    <Text columnGap="20" size="8px" color="#000" weight="medium">
                                        · Privacy · Terms
                                    </Text>
                                </Row>
                            </Col>
                        </FooterBottomWrapper>
                    </Col>
                </StyledFooter>
            ) : (
                <FooterBottomWrapper style={{ display: 'flex' }}>
                    {/* <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6} className={(xs || sm || md || lg) && 'margin-top'}>
                        <Row align="middle" justify={xs || sm || md || lg ? 'space-between' : 'end'} style={{ marginBottom: xs ? '1rem' : '0' }}>
                            <Text style={{ marginRight: sm ? '0px' : '48px' }} size="smallTitle" color="#000" weight="semi-bold">
                                Türkçe (TR)
                            </Text>
                            <Text size="smallTitle" color="#000" weight="semi-bold">
                                <img src={Try} alt="TRY" style={{ marginRight: '5px' }} />
                                TRY
                            </Text>
                        </Row>
                    </Col> */}

                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                        <Row
                            align="middle"
                            justify={xs ? 'start' : 'center'}
                            style={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                columnGap: '10px',
                            }}>
                            <Text size="8px" color="#000" weight="medium">
                                © 2021 Döngü Tüm Hakları Saklıdır.
                            </Text>
                            <Text columnGap="20" size="8px" color="#000" weight="medium">
                                · Privacy · Terms
                            </Text>
                        </Row>
                    </Col>
                </FooterBottomWrapper>
            )}
        </>
    );
};

export default Footer;
