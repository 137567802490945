import React, { useEffect } from 'react';
import { Row } from 'antd';
import { useHistory } from 'react-router';
import { LandingPageStyles } from './styles';
import BannerSection from './BannerSection';
import HowToUseSection from './HowToUseSection';
import InfoSection from './InfoSection';
import FoodWasteSection from './FoodWasteSection';
import BusinessSection from './BusinessSection';
import useSuperApp from '../../../../helpers/Hooks/useSuperApp';
import { OnboardingButton } from './BannerSection/styles';

const LandingPage = () => {
    const history = useHistory();
    const onboardingbutton = () => {
        localStorage.setItem('isOnboardingShown', 'true');
        history.push('/restoran-bul');
    };
    const isSuperApp = useSuperApp();
    useEffect(() => {
        if (isSuperApp && localStorage.getItem('isOnboardingShown') === 'true') {
            history.push('/restoran-bul');
        }
    }, []);
    return isSuperApp ? (
        <>
            <LandingPageStyles span={24}>
                <Row>
                    <HowToUseSection />
                </Row>
                <Row style={{ justifyContent: 'center' }}>
                    <OnboardingButton type="secondary" onClick={() => onboardingbutton()}>
                        Başlayalım
                    </OnboardingButton>
                </Row>
            </LandingPageStyles>
        </>
    ) : (
        <>
            <LandingPageStyles span={24}>
                <Row style={{ backgroundColor: 'white' }}>
                    <BannerSection />
                </Row>
                <Row>
                    <HowToUseSection />
                </Row>
                <Row>
                    <FoodWasteSection />
                </Row>
                <Row>
                    <InfoSection />
                </Row>
                <Row>
                    <BusinessSection />
                </Row>
            </LandingPageStyles>
        </>
    );
};

// <Redirect to="/dashboard" />
export default LandingPage;

// const LandingPage = () => (
//     <LandingPageStyles span={24}>
//         <Row style={{ backgroundColor: 'white' }}>
//             <BannerSection />
//         </Row>
//         <Row>
//             <HowToUseSection />
//         </Row>
//         <Row>
//             <FoodWasteSection />
//         </Row>
//         <Row>
//             < />
//         </Row>
//         <Row>
//             <BusinessSection />
//         </Row>
//     </LandingPageStyles>
// );

// return(
//     {getSuperAppVersion() && onboardingShow ? (
//         <>
//         <LandingPageStyles span={24}>
//             <Row>
//                 <HowToUseSection />
//             </Row>
//         </LandingPageStyles>
//         </>
//     ) : (
//         <>
//         <LandingPageStyles span={24}>
//             <Row style={{ backgroundColor: 'white' }}>
//                 <BannerSection />
//             </Row>
//             <Row>
//                 <HowToUseSection />
//             </Row>
//             <Row>
//                 <FoodWasteSection />
//             </Row>
//             <Row>
//                 <InfoSection />
//             </Row>
//             <Row>
//                 <BusinessSection />
//             </Row>
//         </LandingPageStyles>
//         </>
// );
//     }
// )
