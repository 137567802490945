/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, message, Button, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DividerStyle, ItemRow, InformationStyles, PacketRow, DiscountTextStyle, PriceTextStyle, CustomBtn, TopRow, BottomRow } from './styles.js';
import { Text } from '../../../../../components/Typography/styles.js';
import { fetchStoreBoxes } from '../../../../../../services/Redux/Shared/store/api.js';
import { getAddressActive } from '../../../../../../services/Redux/Shared/profile/api.js';
import { postBasketAdd, resetAddError, fetchBasket, postBasketRemoveAll, getOrder } from '../../../../../../services/Redux/Shared/order/api.js';
import openNotification from '../../../../../components/Notification/index.jsx';
import useResponsive from '../../../../../../helpers/Hooks/useMediaQuery.js';

const PacketPane = (props) => {
    const selector = useSelector;
    const dispatch = useDispatch();
    const { updateCart } = props;
    const { setUpdateCart } = props;
    const { basketList } = selector((state) => state.customerOrderStore);
    const storeData = selector((state) => state.storeStore.storeData);
    const history = useHistory();
    const [update, setUpdate] = useState(false);
    const [selectedBox, setSelectedBox] = useState('');
    const [basket, setBasket] = useState(basketList === 'Sepetinde ürün bulunmamaktadır!' ? [] : basketList);
    const [error, setError] = useState(false);
    const { xs } = useResponsive();
    const user = localStorage.getItem('user');

    useEffect(() => {
        dispatch(fetchStoreBoxes(storeData.id));
        dispatch(getAddressActive());
        dispatch(fetchBasket());
    }, [dispatch, storeData.id]);

    const address = selector((state) => state.profileStore.addressList);
    const response = selector((state) => state.customerOrderStore.addError);
    const activeAddress = selector((state) => state.profileStore.activeAddress);
    const activeOrders = selector((state) => state.customerOrderStore);
    const btn = (
        <Button type="primary" size="small" onClick={() => history.push('/profilim/adreslerim')}>
            Adreslerime Git
        </Button>
    );

    const removeAllFromBasket = async (item) => {
        if (basket.length > 0 && item?.store !== basket[0]?.store?.id) {
            const arr = [];
            basket?.map((element) => arr?.push(element?.id));
            const data = {
                all_box_ids: arr,
            };
            await dispatch(postBasketRemoveAll(data));
            await dispatch(fetchBasket());
            window.location.reload();
        }
    };

    const addToBasket = async (item) => {
        if (user) {
            if (activeAddress !== null && activeAddress !== '' && activeAddress !== undefined) {
                const data = {
                    box_id: item.id,
                    address: activeAddress.id,
                    billing_address: activeAddress.id,
                };
                dispatch(postBasketAdd(data));
                console.log('get orderr');
                setTimeout(() => {
                    setUpdateCart(!updateCart);
                    setUpdate(!update);
                    setError(!error);
                }, 250);
            } else if (activeAddress === '') {
                notification.open({
                    message: 'Adresiniz bulunamadı',
                    description: 'Lütfen adres bilgilerinizi ekleyiniz.',
                    btn,
                });
                // dispatch(resetAddError());
            } else {
                message.error('Lütfen Adres Bilgilerinizi Güncelleyiniz!');
            }
        } else {
            message.error('Lütfen Giriş Yapınız!');
        }
    };

    useEffect(() => {
        const knownResponses = {
            noActiveAddress: 'Aktif adresiniz bulunamadı!',
            responsed: response,
        };

        const { noActiveAddress, responsed } = knownResponses;
        const getBtnTextByResponse = {
            [noActiveAddress]: 'Adres ekle',
            [responsed]: 'Sepeti Boşalt',
        };

        const getBtnHandlerByResponse = {
            [noActiveAddress]: () => history.push('/profilim/adreslerim'),
            [responsed]: () => removeAllFromBasket(selectedBox),
        };
        if (response && activeAddress) {
            openNotification('Sepete Eklenemiyor', responsed, getBtnHandlerByResponse[responsed], true, getBtnTextByResponse[responsed]);
            dispatch(resetAddError());
        }
    }, [error, response]);

    const boxes = selector((state) => state?.storeStore?.boxList);
    let surprise = null;
    let tanim = null;
    const mealPrices = [];
    if (boxes) {
        surprise = boxes?.filter((item) => item.text_name === 'Surprise Box');
        tanim = boxes?.filter((item) => item.text_name !== 'Surprise Box');

        tanim?.map((item) => {
            let temp = 0;
            item.meals.map((meal) => {
                temp += meal.price;
                return 0;
            });
            mealPrices.push(temp);
            return 0;
        });
    }

    if (storeData) {
        return (
            <InformationStyles>
                {surprise && surprise.length > 0 && (
                    <ItemRow align="middle">
                        <Text weight="semi-bold">
                            Sürpriz Paketler <ExclamationCircleOutlined />
                        </Text>
                        <DividerStyle />
                        {surprise.map((item, index) => (
                            <PacketRow align="middle">
                                <Col xs={24} sm={14} style={{ marginTop: xs ? '1rem' : 0, marginBottom: xs ? '0.5rem' : 0 }}>
                                    <Text weight="semi-bold" size="16px">
                                        Sürpriz Paket {index + 1}
                                    </Text>
                                </Col>
                                <Col xs={3}>
                                    <DiscountTextStyle weight="semi-bold" color="#bcbcbc">
                                        {storeData.package_settings.min_order_price}0&nbsp; TL
                                    </DiscountTextStyle>
                                </Col>
                                <Col xs={12} sm={3}>
                                    <PriceTextStyle justify="center">
                                        <Text color="green" weight="semi-bold">
                                            {storeData.package_settings.min_discounted_order_price}&nbsp; TL
                                        </Text>
                                    </PriceTextStyle>
                                </Col>
                                <Col>
                                    {/* eslint-disable-next-line sonarjs/no-redundant-boolean */}
                                    <CustomBtn
                                        type="secondary"
                                        onClick={() => {
                                            setSelectedBox(item);
                                            addToBasket(item);
                                        }}>
                                        <Text size="14px" weight="semi-bold" color="white">
                                            Satın Al
                                        </Text>
                                    </CustomBtn>
                                </Col>
                            </PacketRow>
                        ))}
                    </ItemRow>
                )}
                {tanim ? (
                    <ItemRow align="middle">
                        <Text size="16px" weight="semi-bold">
                            Tanımlı Paketler
                        </Text>
                        <DividerStyle />
                        {tanim.map((item, index) => {
                            if (
                                Array.isArray(activeOrders.basket) &&
                                activeOrders.basket.length !== 0 &&
                                activeOrders.basket.filter((order) => order.id === item.id).length !== 0
                            )
                                return;
                            return (
                                <PacketRow align="middle">
                                    <TopRow align="middle">
                                        <Col xs={24} sm={14} style={{ marginTop: xs ? '1rem' : 0, marginBottom: xs ? '0.5rem' : 0 }}>
                                            <Text weight="semi-bold" size="16px">
                                                {item.text_name}
                                            </Text>
                                        </Col>
                                        <Col xs={3}>
                                            <DiscountTextStyle weight="semi-bold" color="#bcbcbc">
                                                {mealPrices[index]}&nbsp; TL
                                            </DiscountTextStyle>
                                        </Col>
                                        <Col xs={12} sm={3}>
                                            <PriceTextStyle justify="center">
                                                <Text color="green" weight="semi-bold">
                                                    {storeData.package_settings.min_discounted_order_price}&nbsp; TL
                                                </Text>
                                            </PriceTextStyle>
                                        </Col>
                                        <Col>
                                            <CustomBtn
                                                type="secondary"
                                                onClick={() => {
                                                    setSelectedBox(item);
                                                    addToBasket(item);
                                                }}>
                                                <Text size="14px" weight="semi-bold" color="white">
                                                    Satın Al
                                                </Text>
                                            </CustomBtn>
                                        </Col>
                                    </TopRow>
                                    <BottomRow>
                                        <Text color="#707070" weight="light" size="16px">
                                            {item.defined &&
                                                item.meals.map((meal, count) => {
                                                    if (count === item.meals.length - 1) {
                                                        return <span>{meal.name}</span>;
                                                    }
                                                    return <span>{meal.name}, &nbsp;</span>;
                                                })}
                                        </Text>
                                    </BottomRow>
                                </PacketRow>
                            );
                        })}
                    </ItemRow>
                ) : (
                    <>
                        <ItemRow align="middle">
                            <Text size="16px" weight="semi-bold">
                                Tanımlı Paketler
                            </Text>
                            <DividerStyle />
                            <PacketRow align="middle">
                                <TopRow align="middle">
                                    <Col span={24}>
                                        <Text weight="semi-bold" size="16px" color="black">
                                            Size tanımlı paket bulunmamaktadır.
                                        </Text>
                                    </Col>
                                </TopRow>
                            </PacketRow>
                        </ItemRow>
                    </>
                )}
            </InformationStyles>
        );
    }
    return null;
};

export default PacketPane;
