import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
    background-color: white;
    // height: 3rem;
    border: 2px solid #f2f2fc;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
`;

export const EnWrapper = styled(Col)`
    justify-content: space-around;
    width: 60%;
    display: flex;
    margin-right: 0;

    @media (max-width: 600px) {
        width: 90%;
        margin: 0.5rem auto !important;
        margin-bottom: 1rem !important;
    }
`;

export const IconButton = styled.button`
    all: unset;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10px;
`;
