import React from 'react';
import { useHistory } from 'react-router';
import { CustomRow, CustomDivider, CustomButton } from './styles';
import { Text } from '../../../../../../components/Typography/styles';
import PacketRow from './PacketRow';
import RestaurantRow from './RestaurantRow';
import AddressRow from './AddressRow';
import openNotification from '../../../../../../components/Notification/index';

const AddressPane = (props) => {
    const { setKey, packet, setPacket, setAddressInfo, addressInfo } = props;
    const history = useHistory();

    const redirectTo = () => {
        history.push('/profilim/adreslerim');
    };

    const onDevamEt = () => {
        // console.log(addressInfo);
        // if (addressInfo.fatura)
        if (addressInfo.adres) {
            window.scrollTo(0, 0);
            setKey('2');
        } else {
            openNotification(
                'Fatura Adres Yok',
                'Fatura adresiniz olana kadar sipariş veremezsiniz. Fatura adresi eklemek için tıklayınız',
                redirectTo,
                true
            );
        }
    };
    return (
        <CustomRow>
            <PacketRow packet={packet} setPacket={setPacket} />
            <CustomDivider />
            <RestaurantRow />
            {/* {packet ? <RestaurantRow /> : <></>} */}
            <AddressRow packet={packet} setAddressInfo={setAddressInfo} />
            {/* The following part has been removed as per requested, however, uncommenting it shall make it work directly, functionalities are already implemented. */}
            <CustomRow className="button-row removeSmall" justify="center">
                <CustomButton type="secondary" onClick={() => onDevamEt()}>
                    <Text weight="semi-bold" size="16px" color="white">
                        Devam Et
                    </Text>
                </CustomButton>
            </CustomRow>
        </CustomRow>
    );
};

export default AddressPane;
