/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { Row } from 'antd';
import {
    HowToUseStyles,
    ReserveWrapper,
    ChooseRestorantWrapper,
    PaymentWrapper,
    TakeYourOrderWrapper,
    SafeOurWorldWrapper,
    Carousel,
    HowToUseMobileStyles,
} from './styles';
import { Text } from '../../../../components/Typography/styles';
import akisWeb from '../../../../../Assets/Shared/Landing/akis_web.png';
import onboarding1 from '../../../../../Assets/Shared/Landing/onboarding/dongu_onboarding-01.png';
import onboarding2 from '../../../../../Assets/Shared/Landing/onboarding/dongu_onboarding-02.png';
import onboarding3 from '../../../../../Assets/Shared/Landing/onboarding/dongu_onboarding-03.png';
import onboarding4 from '../../../../../Assets/Shared/Landing/onboarding/dongu_onboarding-04.png';
import onboarding5 from '../../../../../Assets/Shared/Landing/onboarding/dongu_onboarding-05.png';
import defaultOnboarding1 from '../../../../../Assets/Shared/Landing/onboarding/default/d_dongu_onboarding-01.svg';
import defaultOnboarding2 from '../../../../../Assets/Shared/Landing/onboarding/default/d_dongu_onboarding-02.svg';
import defaultOnboarding3 from '../../../../../Assets/Shared/Landing/onboarding/default/d_dongu_onboarding-03.svg';
import defaultOnboarding4 from '../../../../../Assets/Shared/Landing/onboarding/default/d_dongu_onboarding-04.svg';
import defaultOnboarding5 from '../../../../../Assets/Shared/Landing/onboarding/default/d_dongu_onboarding-05.svg';
import useSuperApp from '../../../../../helpers/Hooks/useSuperApp';

const data = [onboarding1, onboarding2, onboarding3, onboarding4, onboarding5];
const defaultOnboardingData = [defaultOnboarding1, defaultOnboarding2, defaultOnboarding3, defaultOnboarding4, defaultOnboarding5];
const HowToUseSection = () => (
    <>
        <HowToUseMobileStyles>
            {!useSuperApp() ? (
                <>
                    <Carousel autoplay arrows>
                        {data.map((img, i) => (
                            <div style={{}} key={i}>
                                <img className="image-size" src={img} alt="food" />
                            </div>
                        ))}
                    </Carousel>
                </>
            ) : (
                <>
                    <Carousel autoplay arrows>
                        {defaultOnboardingData.map((image, i) => (
                            <div style={{}} key={i}>
                                <img className="image-size" src={image} alt="food" />
                            </div>
                        ))}
                    </Carousel>
                </>
            )}
        </HowToUseMobileStyles>

        <HowToUseStyles span={24} backgroundImage={akisWeb}>
            <Row style={{ width: '100%' }}>
                {/* 
    <img src={howToUse} alt="" style={{ width: '100%' }} /> */}
                <ReserveWrapper>
                    {/* <img src={reserve} alt="" width="30%" /> */}
                    <Row style={{ width: '30%', alignItems: 'center' }} justify="center">
                        <Text size="regular" weight="medium" style={{ textAlign: 'center' }}>
                            İstediğin restoranın sunduğu paketi sepetine ekle.
                        </Text>
                    </Row>
                </ReserveWrapper>
                <PaymentWrapper>
                    <Row style={{ width: '45%', alignItems: 'center' }} justify="center">
                        <Text size="regular" weight="medium" style={{ textAlign: 'center' }}>
                            Online ödeme yöntemi ile siparişini tamamlayabilirsin.
                        </Text>
                    </Row>
                    {/* <img src={payment} alt="" width="30%" /> */}
                </PaymentWrapper>
                <TakeYourOrderWrapper>
                    {/* <img src={takeYourOrder} alt="" width="30%" /> */}
                    <Row style={{ width: '30%', alignItems: 'center' }} justify="center">
                        <Text size="regular" weight="medium" style={{ textAlign: 'center' }}>
                            Paketini restorana gelerek teslim alabilir veya kapıda teslimi seçebilirsin.
                        </Text>
                    </Row>
                </TakeYourOrderWrapper>
                <SafeOurWorldWrapper>
                    {/* <img src={safeOurWorld} alt="" width="30%" /> */}
                    <Row style={{ width: '50%', alignItems: 'center' }} justify="center">
                        <Text size="regular" weight="medium" style={{ textAlign: 'center' }}>
                            Gıda israfıyla mücadelede bizi yalnız bırakmadığın için teşekkürler!
                        </Text>
                    </Row>
                </SafeOurWorldWrapper>
                <ChooseRestorantWrapper>
                    {/* <img src={chooseRestorant} alt="" /> */}
                    <Row style={{ width: '50%', alignItems: 'center' }}>
                        {/* <Col span={4}>
                <img src={dot} alt="" />
            </Col> 
            <Col span={20} style={{ marginTop: 25 }}> */}
                        <Text size="regular" weight="medium" style={{ textAlign: 'center' }}>
                            Çevreye duyarlı Döngü işletmelerini keşfet.
                        </Text>
                        {/* </Col> */}
                    </Row>
                </ChooseRestorantWrapper>
            </Row>
        </HowToUseStyles>
    </>
);

export default HowToUseSection;
