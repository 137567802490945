import styled from 'styled-components';
import { Row, Checkbox, Button as AButton } from 'antd';
import Button from '../../../../../../../components/Button/index';

export const CustomRow = styled(Row)`
    width: 100%;

    &.removeSmall {
        @media (min-width: 1024px) {
            display: none;
        }
    }

    &.tutar-row {
        margin-top: 5px;
    }

    &.price-row {
        margin-top: 9px;
    }

    &.button-row {
        margin-top: 20px;
    }
`;

export const CustomCheckbox = styled(Checkbox)`
    margin-bottom: 10px;
    .ant-checkbox {
        .ant-checkbox-inner {
            width: 22px;
            height: 22px;
            border-radius: 5px;
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background: #8fd89f;
            border-color: #8fd89f;
        }
    }

    .ant-checkbox-wrapper:hover .ant-checkbox,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #d1d0d0;
    }

    .ant-checkbox-checked::after {
        border: 1px solid #ffffff;
    }

    .ant-typography {
        margin-left: 10px;
    }
`;

export const CustomButton = styled(AButton)`
    width: 250px;
    height: 48px;
    background: #c7c7c7 !important;
    border-radius: 5px;
`;

export const OdemeButton = styled(Button)`
    width: 100%;
    height: 48px;
`;

export const KobilPayButton = styled(Button)`
    width: 100%;
    height: 48px;
`;
