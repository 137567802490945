import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 56px;
    border-radius: 5px;
    border: 2px solid #f2f2fc;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
`;

export const CountryCode = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    color: #424242;
    margin-left: 5px;
    margin-right: 5px;
`;

export const CountryListWrapper = styled.div`
    position: absolute;
    cursor: pointer;
    width: 398px;
    max-height: 252px;
    border-radius: 5px;
    border: 2px solid #f2f2fc;
    background-color: #fff;
    z-index: 100;
    top: 60px;
    left: -2px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: scroll;
`;

export const CountryListItem = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    font-family: Montserrat;
    color: #424242;

    &:hover {
        background-color: #f2f2fc;
    }
`;
