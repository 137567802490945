import { Row } from 'antd';
import React from 'react';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';
import { Text } from '../../../../components/Typography/styles';
import { CustomButton, IconCol } from './styles';

const CategoryIcon = (props) => {
    const { xs } = useResponsive();
    const { icon, text, color } = props;

    return (
        <IconCol>
            <CustomButton style={{ gap: 10 }}>
                <Row style={{ background: color, borderRadius: '50%', height: xs ? 50 : 80, width: xs ? 50 : 80 }} justify="center" align="middle">
                    <img src={icon} alt="img" style={{ width: '50%', margin: 'auto' }} />
                </Row>
                <Text style={{ fontSize: '0.75rem', fontWeight: 300, lineHeight: 1.5 }}>{text}</Text>
            </CustomButton>
        </IconCol>
    );
};

export default CategoryIcon;
