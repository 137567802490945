import styled from 'styled-components';
import { Col, Carousel as carousel } from 'antd';
import { between } from 'polished';
// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const HowToUseStyles = styled(Col)`
    overflow: hidden;
    padding-top: 30px;
    /* 
    padding-left: ${between('0px', '120px')};
    padding-right: ${between('0px', '120px')}; */
    @media only screen and (max-width: 768px) {
        display: none;
    }
    background-repeat: no-repeat;
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    height: 60vw;
    width: 100%;
    background-position: 55% 10%;
    background-size: 108%;
    .content-stylings {
        height: 377px;
        .ant-carousel .slick-prev {
            width: 30px;
            height: 40px;
            left: 25px;
            z-index: 1;
        }
        .ant-carousel .slick-next {
            width: 30px;
            height: 40px;
            right: 25px;
            z-index: 1;
        }
    }
    .image-size {
        width: 100%;
    }
    /* @media (max-width: 768px) {
        height: 70vw;
    } */
`;
export const HowToUseMobileStyles = styled(Col)`
    width: 100%;
    display: none;

    @media only screen and (max-width: 768px) {
        display: block;
        // margin-top: 0px;
        margin-bottom: -12px;
    }

    .content-stylings {
        height: 377px;
        .ant-carousel .slick-prev {
            width: 30px;
            height: 40px;
            left: 25px;
            z-index: 1;
        }
        .ant-carousel .slick-next {
            width: 30px;
            height: 40px;
            right: 25px;
            z-index: 1;
        }
    }
    .slick-dots li.slick-active button:before {
        opacity: 0;
    }
    .slick-dots li button:before {
        opacity: 0;
    }
    .image-size {
        width: 100%;
    }
`;
export const ReserveWrapper = styled(Col)`
    position: absolute;
    width: 50vw;
    bottom: ${between('240px', '660px', '768px', '1920px')};
    left: 26%;
    flex-direction: 'column';
`;

export const ChooseRestorantWrapper = styled(Col)`
    position: absolute;
    bottom: ${between('95px', '295px', '768px', '1920px')};
    left: 15%;
    flex-direction: 'column';
`;

export const PaymentWrapper = styled(Col)`
    position: absolute;
    width: 50vw;

    bottom: ${between('180px', '500px', '768px', '1920px')};
    left: 38%;
    flex-direction: 'column';
`;
export const TakeYourOrderWrapper = styled(Col)`
    position: absolute;
    width: 50vw;
    bottom: ${between('230px', '700px', '768px', '1920px')};
    left: 55%;
    flex-direction: 'column';
`;
export const SafeOurWorldWrapper = styled(Col)`
    position: absolute;
    bottom: ${between('60px', '340px', '768px', '1920px')};
    left: 75%;
    flex-direction: 'column';
`;
export const Carousel = styled(carousel)``;
