/* eslint-disable sonarjs/no-identical-functions */
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Text } from '../../../../components/Typography/styles';

export const CardWrapper = styled(Row)`
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #ffffff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    height: 59%;
    justify-content: space-between;
`;

export const ContentWrapper = styled(Row)`
    margin-bottom: 32px;
    height: 303px;
    cursor: pointer;
    margin-bottom: 1rem;
`;

export const DownContent = styled(Col)`
    width: 334px;
    background-color: white;
    border-radius: 8px;
    @media (max-width: 576px) {
        width: 365px;
    }
`;

export const UpTextWrapper = styled(Row)`
    align-items: center;
    background-color: #f38262;
    border-radius: 8px;
    width: 22%;
    height: 18%;
    margin-left: 3%;
    margin-top: 3%;
    justify-content: center;
`;

export const UpTextStyle = styled(Text)``;

export const IconWrapper = styled(Row)`
    margin-right: 3%;
    margin-top: 3%;
`;
export const MiddleSectionStyle = styled(Row)`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 2px solid #f5f9fc;
`;

export const ShopNameStyle = styled(Text)`
    color: #424242;
`;

export const LogoStyle = styled(Col)`
    background-image: ${({ backgroundImage }) => {
        if (backgroundImage) {
            return `url(${backgroundImage})!important`;
        }
    }};
    background-color: #ffffff;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: padding-box;
    height: 45px;
    width: 75px;
    border: 2px solid #f2f2fb;
    border-radius: 4px;
    margin-top: -25%;
`;

export const RatingStyle = styled(Col)`
    align-items: center;
`;

export const RatingTextStyle = styled(Text)`
    margin-left: 10px;
`;

export const LocationTextStyle = styled(Text)`
    color: #837f7f;
`;
export const EmptyDiv = styled(Col)``;

export const BottomSectionStyle = styled(Row)`
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
`;

export const BottomContainerStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    align-items: center;
    background-color: #f5f9fc;
`;

export const BottomTextStyle = styled(Text)`
    margin-left: 10px;
`;

export const DiscountTextStyle = styled(Row)`
    align-items: center;
`;
export const PriceTextStyle = styled(Row)`
    border: 4px solid #f5f9fc;
    width: 69px;
    height: 40px;
    justify-content: center;
    align-items: center;
    background-color: #f5f9fc;
    margin-left: 10px;
`;
