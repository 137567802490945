/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Text } from '../../../../../../components/Typography/styles';
import ArrowRight from '../../../../../../../Assets/Shared/Profile/arrowRight.svg';
// import editIcon from '../../../../../../../Assets/Shared/Profile/editIcon.svg';
import { StyledDiv, DetailsDiv } from './styles';
import { postAddressActive } from '../../../../../../../services/Redux/Shared/profile/api';
import { CustomRadio } from '../../../Content/AddressPane/PaymentAddress/styles';

function Index({ address, setAddress }) {
    const dispatch = useDispatch();
    const selector = useSelector;
    const activeAddress = selector((state) => state.profileStore.activeAddress.id);
    const [radioValue, setRadioValue] = useState(activeAddress || null);

    const onRadioChange = (value, id) => {
        setRadioValue(value);
        const data = {
            active_address: id,
        };
        dispatch(postAddressActive(data));
    };

    useEffect(() => {
        if (activeAddress) setRadioValue(activeAddress);
    }, [activeAddress]);
    return (
        <StyledDiv style={{ justifyContent: 'end', paddingLeft: '0px', marginRight: '0px', marginTop: '10px' }}>
            <Row style={{ minWidth: '100%' }} align="middle">
                <Col span={7}>
                    <DetailsDiv style={{ justifyContent: 'end' }}>
                        <CustomRadio.Group
                            value={radioValue}
                            style={{ color: 'green' }}
                            size="large"
                            onChange={(e) => onRadioChange(e.target.value, e.target.number)}>
                            <CustomRadio value={address.id} number={address.id} span={16}>
                                {/* <img src={editIcon} onClick={() => setAddress(address)} alt="sup" /> */}
                            </CustomRadio>
                        </CustomRadio.Group>
                    </DetailsDiv>
                </Col>
                <Col span={14} onClick={() => setAddress(address)}>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.name}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="medium" size="description">
                            {address?.province} {/* {address?.province} ({address?.address?.substring(0, address?.address?.indexOf(','))}) */}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {JSON.parse(localStorage.getItem('user'))?.email}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.address}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.phone_number?.replace(/(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}
                        </Text>
                    </Row>
                    <Row>
                        <Text weight="light" size="small">
                            {address?.description}
                        </Text>
                    </Row>
                </Col>
                <Col span={2} onClick={() => setAddress(address)}>
                    <img src={ArrowRight} alt="details" />
                </Col>
            </Row>
        </StyledDiv>
    );
}
export default Index;
