/* eslint-disable no-console */
import * as actionTypes from './actionTypes';

export const authStartAction = () => ({
    type: actionTypes.AUTH_START,
});

export const authSuccessAction = (_userData) => {
    localStorage.setItem('token', _userData.token);
    localStorage.setItem('user', JSON.stringify(_userData.user));
    if (JSON.parse(localStorage.getItem('user')).stores) {
        return {
            type: actionTypes.AUTH_SUCCESS,
            userData: _userData,
        };
    }
    return {
        type: actionTypes.AUTH_SUCCESS_CUSTOMER,
        userData: _userData,
    };
};

export const authFailAction = () => ({
    type: actionTypes.AUTH_FAIL,
});

export const authLogOutAction = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return {
        type: actionTypes.AUTH_LOG_OUT,
    };
};

export const facebookConnectStartAction = () => ({
    type: actionTypes.FACEBOOK_CONNECT_START,
});

export const facebookConnectSuccessAction = (data) => ({
    type: actionTypes.FACEBOOK_CONNECT_SUCCESS,
    payload: data,
});

export const facebookConnectFailAction = (error) => ({
    type: actionTypes.FACEBOOK_CONNECT_FAIL,
    payload: error,
});

export const socialDisconnectStartAction = () => ({
    type: actionTypes.SOCIAL_DISCONNECT_START,
});

export const socialDisconnectSuccessAction = (data) => ({
    type: actionTypes.SOCIAL_DISCONNECT_SUCCESS,
    payload: data,
});

export const socialDisconnectFailAction = (error) => ({
    type: actionTypes.SOCIAL_DISCONNECT_FAIL,
    payload: error,
});

export const kobilOpenIDConnectFailAction = (error) => ({
    type: actionTypes.KOBIL_OPENID_CONNECT_FAIL,
    payload: error,
});
export const kobilOpenIDConnectSuccessAction = (data) => ({
    type: actionTypes.KOBIL_OPENID_CONNECT_SUCCES,
    payload: data,
});
