import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useDispatch } from 'react-redux';
import { theme } from './presentation/themes/theme';
import Routes from './services/routes';
import { getComposeUrl, OIDLogin } from './services/Redux/auth/api';
import getSuperAppVersion from './helpers/getSuperAppVersion';

export default function App() {
    const dispatch = useDispatch();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    const sessionState = urlParams.get('session_state');
    const isSuperApp = getSuperAppVersion();
    React.useEffect(() => {
        if (isSuperApp) {
            if (!code) {
                dispatch(getComposeUrl());
            } else {
                try {
                    dispatch(OIDLogin(code, sessionState));
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
    );
}
