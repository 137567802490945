import styled from 'styled-components';
import { Row, Col } from 'antd';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

export const IconCol = styled(Col)`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30;
    @media only screen and (max-width: 400px) {
        margin-right: 0;
    }
`;

export const IconWrapper = styled(Row)`
    margin-bottom: 3rem;
    width: 100%;
    cursor: pointer;

    @media (max-width: 600px) {
        margin-top: 1rem;
    }
`;

export const CustomButton = styled.button`
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    & > .ant-row {
        @media only screen and (max-width: 1024px) {
            max-height: 4rem;
            max-width: 4rem;
            margin: 0 auto;
        }
    }
`;

export const StyledSlider = styled(Slider)`
    background-color: #ffffff;
    padding-top: 1rem;
    border-radius: 18px;
    padding-bottom: 0.5rem;
    & .slick-dots li button:before {
        font-size: 10px;
    }
    & .slick-dots li.slick-active button:before {
        color: #8fd89f;
        font-weight: bold;
        opacity: 100%;
    }
`;

export const CircleBasket = styled.button`
    background: red;
    color: red;
    border: none;
    cursor: pointer;
    border: 5px solid red;
    border-radius: 50%;
    padding-bottom: 0.3rem;
    padding-top: 0.5rem;
    padding-right: 0.1rem;
    padding-left: 0.1rem;
`;
