import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import ControlPanelPage from '../presentation/pages/Dashboard/ControlPanelPage';
import CalendarPage from '../presentation/pages/Dashboard/CalendarPage';
import OrdersPage from '../presentation/pages/Dashboard/OrdersPage/index';
import StorePage from '../presentation/pages/Dashboard/StorePage/index';
import DashboardLayout from '../presentation/pages/Dashboard/components/Layout';
import DashboardHeader from '../presentation/pages/Dashboard/components/Layout/Header';
import DashboardSider from '../presentation/pages/Dashboard/components/Layout/Sider';
import DashboardContent from '../presentation/pages/Dashboard/components/Layout/Content';
import BusinessLoginPage from '../presentation/pages/Shared/BusinessLoginPage';
import PackageSettings from '../presentation/pages/Dashboard/PackageSettings/index';
import DashboardHelpcenterPage from '../presentation/pages/Dashboard/HelpcenterPage';
import DashboardStatisticsPage from '../presentation/pages/Dashboard/StatisticsPage';
import DashboardMenuSettingsPage from '../presentation/pages/Dashboard/MenuSettingsPage';
import LandingPage from '../presentation/pages/Shared/LandingPage';
import LayoutHeader from '../presentation/components/Layout/Header';
import LayoutFooter from '../presentation/components/Layout/Footer';
import FoodWaste from '../presentation/pages/Shared/FoodWaste';
import AboutUs from '../presentation/pages/Shared/AboutUs';
import ForBusinessPage from '../presentation/pages/Shared/ForBusinessPage';
import LoginDemo from '../presentation/pages/Shared/LoginDemo/index';
import FaqPage from '../presentation/pages/Shared/FaqPage/index';
import PrivacyPolicy from '../presentation/pages/Shared/PrivacyPolicy/index';
import BusinessRegisterForm from '../presentation/pages/Shared/BusinessRegisterForm';
import CookiePolicy from '../presentation/pages/Shared/AboutUs/CookiePolicy';
import KvkkPolicy from '../presentation/pages/Shared/AboutUs/Kvkk';
import UserPolicy from '../presentation/pages/Shared/AboutUs/UserAgreement';
import Support from '../presentation/pages/Shared/Support';
import DashboardNotificationPage from '../presentation/pages/Dashboard/NotificationPage';

// CUSTOMER PAGES
import FindRestaurant from '../presentation/pages/Shared/FindRestaurant';
import RestourantDetail from '../presentation/pages/Shared/RestaurantDetail';
import SignUp from '../presentation/pages/Shared/SignUp';
import CartPage from '../presentation/pages/Shared/CartPage';
import ProfilePage from '../presentation/pages/Shared/ProfilePage';
import PasswordReset from '../presentation/pages/Shared/PasswordReset';
import NotificationPage from '../presentation/pages/Shared/NotificationPage';
import UserSettings from '../presentation/pages/Shared/UserSettings';
import Favourites from '../presentation/pages/Shared/Favourites';
import { IsUserRedirect } from '../helpers/routes';
import useCustomerUser from '../helpers/Hooks/useCustomerUser';

// MOBILE SPECIFIC PAGES
import RestaurantInformation from '../presentation/pages/Shared/RestaurantDetail/Tabs/MobileInformationContent/RestaurantInformation';
import RestaurantCategories from '../presentation/pages/Shared/RestaurantDetail/Tabs/MobileInformationContent/Categories';
import RestaurantWorkingHours from '../presentation/pages/Shared/RestaurantDetail/Tabs/MobileInformationContent/WorkingHours';
import useSuperApp from '../helpers/Hooks/useSuperApp';

const { Content } = Layout;
const Home = ({ match }) => {
    const isSuperApp = useSuperApp();
    const onboardingCheck = localStorage.getItem('isOnboardingShown');
    console.log(localStorage.getItem('isOnboardingShown'), 'onboarding routes');
    const { user: userData } = useCustomerUser();
    return userData && userData.stores ? (
        <Redirect to="/dashboard" />
    ) : (
        <>
            <Route path={match.url} exact component={LandingPage} />
            <LayoutHeader userData={userData} />
            <Content style={{ backgroundColor: '#F5F9FC', minHeight: 'calc(100vh - 100px)' }}>
                <Switch>
                    <Route path={`${match.url}admin`} exact>
                        <div>Admin Login</div>
                    </Route>
                    <Route path={`${match.url}business`} exact component={BusinessLoginPage} />
                    <Route path={`${match.url}gida-israfi`} exact component={FoodWaste} />
                    <Route path={`${match.url}hakkimizda`} exact component={AboutUs} />
                    <Route path={`${match.url}isletmeler-icin`} exact component={ForBusinessPage} />
                    <IsUserRedirect loggedInPath="/" userData={userData} path={`${match.url}giris`}>
                        <LoginDemo />
                    </IsUserRedirect>
                    <IsUserRedirect loggedInPath="/" userData={userData} path={`${match.url}uye-ol`}>
                        <SignUp />
                    </IsUserRedirect>
                    <Route path={`${match.url}sikca-sorulan-sorular`} exact component={FaqPage} />
                    <Route path={`${match.url}gizlilik-politikasi`} exact component={PrivacyPolicy} />
                    <Route path={`${match.url}cerez-politikasi`} exact component={CookiePolicy} />
                    <Route path={`${match.url}kvkk`} exact component={KvkkPolicy} />
                    <Route path={`${match.url}kullanici-sozlesmesi`} exact component={UserPolicy} />
                    <Route path={`${match.url}destek`} exact component={Support} />
                    <Route path={`${match.url}restoran-bul`} exact component={FindRestaurant} />
                    <Route path={`${match.url}restoran-detay/:storeId`} exact component={RestourantDetail} />
                    <Route path={`${match.url}restoran-detay/:storeId/magaza-bilgisi`} exact component={RestaurantInformation} />
                    <Route path={`${match.url}restoran-detay/:storeId/gida-kategorileri`} exact component={RestaurantCategories} />
                    <Route path={`${match.url}restoran-detay/:storeId/calicma-saatleri`} exact component={RestaurantWorkingHours} />
                    <Route path={`${match.url}sepetim`} exact component={CartPage} />
                    <Route path={`${match.url}profilim/`} component={ProfilePage} />
                    <Route path={`${match.url}bildirimlerim/`} exact component={NotificationPage} />
                    <Route path={`${match.url}ayarlar`} exact component={UserSettings} />
                    <Route path={`${match.url}favorilerim`} exact component={Favourites} />
                    <Route path={`${match.url}password-reset/:uidb64/:token`} exact component={PasswordReset} />
                </Switch>
            </Content>
            <LayoutFooter />
        </>
    );
};

const Dashboard = ({ match }) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    return !userData ? (
        <Redirect to="/" />
    ) : (
        <DashboardLayout color="#F5F9FC" style={{ height: '100vh' }}>
            <DashboardHeader />
            <DashboardLayout>
                <DashboardSider />
                <DashboardContent>
                    <Switch>
                        <Route exact path={`${match.url}/`} component={ControlPanelPage} />
                        <Route exact path={`${match.url}/calendar`} component={CalendarPage} />
                        <Route exact path={`${match.url}/packet`} component={PackageSettings} />
                        <Route exact path={`${match.url}/menusettings`} component={DashboardMenuSettingsPage} />
                        <Route exact path={`${match.url}/stats`} component={DashboardStatisticsPage} />
                        <Route exact path={`${match.url}/store`} component={StorePage} />
                        <Route exact path={`${match.url}/orders`} component={OrdersPage} />
                        <Route exact path={`${match.url}/helpcenter`} component={DashboardHelpcenterPage} />
                        <Route exact path={`${match.url}/notification`} component={DashboardNotificationPage} />
                    </Switch>
                </DashboardContent>
            </DashboardLayout>
        </DashboardLayout>
    );
};

const Routes = () => (
    <Router>
        <Switch>
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/" component={Home} />
            {/* <Route path="/dashboard" component={Dashboard} />  
            <Route path="/admin" component={Admin} /> */}
            <Route path="/businessregisterform" exact component={BusinessRegisterForm} />
        </Switch>
    </Router>
);

export default Routes;
