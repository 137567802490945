/// ////MOBILE
import React from 'react';
import { Row } from 'antd';
import { RecycleImage, EnvironmentStyles, EnvironmentTitle, EnvironmentContentWrapper, EnvironmentContent } from './styles';
import { Paragraph, Text } from '../../../../components/Typography/styles';
import Recycle from '../../../../../Assets/Shared/FoodWaste/environment.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';

import Bg from '../../../../../Assets/Shared/FoodWaste/foodwaste.svg';

const EnvironmentSection = () => {
    const { sm } = useMediaQuery();
    return (
        <EnvironmentStyles backgroundImage={sm ? '' : Bg} style={{ marginBottom: sm ? '75px' : '' }}>
            <EnvironmentContentWrapper>
                <EnvironmentTitle style={{ width: sm ? '' : '70%' }}>
                    <Text
                        weight="bold"
                        color="#F38262"
                        size="bigTitle"
                        style={{ width: '100%', textAlign: sm && 'center', marginTop: sm ? '-140px' : '' }}>
                        Gıda İsrafının Çevreye Etkisi
                    </Text>
                </EnvironmentTitle>
                <EnvironmentContent style={{ width: sm ? '' : '70%' }}>
                    <Paragraph color="#000" weight="medium" size="description" style={{ textAlign: sm ? 'center' : 'start' }}>
                        Gıda, üretimde su kullanımının en yoğun olduğu sektörler arasında yer alıyor. Küresel çapta her yıl israf edilen toplam
                        gıdanın üretimi için, 250 km³ su tüketimi gerçekleştiği biliniyor. 9 milyar kişinin günlük su ihtiyacını karşılayabilecek bu
                        rakam, Avrupa’nın en uzun nehri olan İdil (Volga) Nehri’nin yıllık debisine ve Cenevre Gölü hacminin 3 katına karşılık
                        gelmekte.
                    </Paragraph>
                </EnvironmentContent>
                <Row style={{ width: '100%' }} justify="center">
                    <RecycleImage src={Recycle} alt="recyle" style={{ display: sm ? 'flex' : 'none', width: sm && '45%' }} />
                </Row>
            </EnvironmentContentWrapper>
        </EnvironmentStyles>
    );
};
export default EnvironmentSection;
