/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Text } from '../../../../components/Typography/styles';
import { ContentStyle, UpTextWrapper, DownTextWrapper } from './styles';

export default function BannerSection() {
    return (
        <ContentStyle>
            <UpTextWrapper>
                <Text style={{ fontSize: 45, lineHeight: 1 }} weight="bold" color="#FFFFFF">
                    ACIKTIN MI ? <br />
                </Text>
            </UpTextWrapper>
            <DownTextWrapper>
                <Text style={{ fontSize: 45, lineHeight: 1 }} weight="bold" color="#FFFFFF">
                    O HALDE DOĞRU YERDESİN!
                </Text>
            </DownTextWrapper>
        </ContentStyle>
    );
}
