/* eslint-disable react/display-name */
/* eslint-disable no-unused-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable sonarjs/no-identical-functions */
import { Col } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import useResponsive from '../../../../../helpers/Hooks/useMediaQuery';
import CategoryIcon from './CategoryIcon';
import { IconWrapper, StyledSlider } from './styles';

const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 4,
    arrows: false,
};

export default function Categories({ setSelectedCategories }) {
    const selector = useSelector;
    const { xs } = useResponsive();

    const categories = selector((state) => state.boxStore.categoryNamesList);
    if (categories) {
        return (
            <IconWrapper>
                {xs ? (
                    <Col span={24}>
                        <StyledSlider {...settings}>
                            {categories?.results?.map((item) => (
                                <CategoryIcon
                                    item={item}
                                    icon={item.photo}
                                    setSelectedCategories={setSelectedCategories}
                                    text={item.name}
                                    color={item.color}
                                />
                            ))}
                        </StyledSlider>
                    </Col>
                ) : (
                    categories?.results?.map((item) => (
                        <CategoryIcon
                            item={item}
                            icon={item.photo}
                            setSelectedCategories={setSelectedCategories}
                            text={item.name}
                            color={item.color}
                        />
                    ))
                )}
            </IconWrapper>
        );
    }
    return null;
}
