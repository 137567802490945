import { Col, Row, Divider } from 'antd';
import styled from 'styled-components';
import Button from '../../../components/Button/index';

export const Outside = styled(Row)`
    background-color: #f5f9fc;
    margin: 0 auto !important;
    max-width: 1430px;
    padding: 14% 3%;
    padding-top: 150px;
    width: 100%;
    min-height: 100vh;

    .mobileShow {
        display: none;
    }

    @media (min-width: 1500px) {
        padding-left: 0;
        padding-right: 0;
    }
    // min-height: 87vh; footer show
    @media (max-width: 576px) {
        padding-top: 120px;
        padding-bottom: 3%;
        padding-left: 0;
        padding-right: 0;
        margin: 0 0;
        min-height: 96vh;
        width: 100%;
        .mobileHide {
            display: none;
        }
        .mobileShow {
            display: block;
        }
    }
`;

export const LeftContainer = styled(Col)`
    background-color: white;
    border-radius: 18px;
    width: 100%;
    min-height: auto;
    height: auto;
    padding-bottom: 40px;
    filter: drop-shadow(0px 0px 10px #cfcfda);
`;

export const RightContainer = styled(Col)`
    width: 334px;
    height: 390px;
    border-radius: 18px;
`;

export const TitleRow = styled(Row)`
    width: 100%;
    padding-top: 30px;
`;

export const CustomDivider = styled(Divider)`
    width: 100% !important;
    border-top: 4px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 0px;

    &.user-divider {
        margin-left: 20px;
        margin-top: 5px;
    }
`;

export const CustomRow = styled(Row)`
    width: 100%;
    &.main-row {
        margin-top: 20px;
        padding-right: 23px;
        margin-bottom: 30px;
    }
    &.user-row {
        margin-left: 30px;
    }
`;

export const CustomButtom = styled(Button)`
    width: 372px;
    height: 48px;
`;
