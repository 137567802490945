import styled from 'styled-components';
import { Col, Row } from 'antd';
import { between } from 'polished';
import Button from '../../../../components/Button';
import { HORIZONTAL_PADDING, VERTICAL_PADDING } from '../../../../../helpers/constants';

// TODO ({ theme }) => theme.layoutStyles.paddingHorizontal)

export const BannerStyles = styled(Col)`
    padding-bottom: ${VERTICAL_PADDING};
    box-sizing: border-box;

    @media (max-width: 768px) {
        display: flex;
        justify-content: center;
        padding-top: ${VERTICAL_PADDING};
    }
    @media (min-width: 768.05px) {
        margin-top: ${between('50px', '100px', '320px', '1920px')};
        height: 100%;
        background-repeat: no-repeat;
        background-image: ${({ backgroundImage }) => {
            if (backgroundImage) {
                return `url(${backgroundImage})!important`;
            }
        }};
        background-position: 90% 30%;
        background-size: 35%;
    }
`;

export const MottoWrapper = styled(Row)`
    padding-left: ${HORIZONTAL_PADDING};
    padding-right: ${HORIZONTAL_PADDING};

    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const TitleWrapper = styled(Row)`
    width: ${({ width }) => width};
    margin-top: ${between('50px', '100px', '320px', '1920px')};
`;

export const FindRestorantButton = styled(Button)`
    width: ${({ width }) => width};
    // width={sm ? '100%' : '212px'}
    height: 48px;
    width: 100%;
    @media (min-width: 768.7px) {
        margin-right: 30px;

        width: 212px;
    }
    margin-bottom: 30px;
`;
export const DownloadAppButton = styled(Button)`
    width: ${({ width }) => width};
    height: 48px;
`;
export const OnboardingButton = styled(Button)`
    width: ${({ width }) => width};
    height: 48px;
    margin-bottom: 35px;
`;

export const ButtonWrapper = styled(Row)`
    margin-top: ${between('20px', '42px', '320px', '1920px')};
    @media (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;
export const BackgroundImage = styled.img`
    width: 58%;
    position: absolute;
    right: 0px;
    bottom: 80px;
    z-index: -2;
    padding-right: ${between('0px', '120px')};
`;
