/* eslint-disable no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import { message } from 'antd';
import axios from 'axios';
import * as actions from './actions';
import xmlDelete from '../../../Api/request';

export const createCard = (info) => async (dispatch, getState, service) => {
    dispatch(actions.createCardStartAction());
    await service({
        path: 'iyzico/cards/register',
        method: 'POST',
        sendToken: true,
        body: info,
    })
        .then((data) => {
            dispatch(actions.createCardSuccessAction(data));
            message.success('Kart Kaydı Başarı ile Tamamlandı!');
        })
        .catch((error) => {
            dispatch(actions.createCardFailAction(error));
            message.error('Bir Hata Oluştu!');
        });
};

// GET LIST

export const getCardList = () => async (dispatch, getState, service) => {
    dispatch(actions.getCardStartAction());
    await service({
        path: 'iyzico/cards/list',
        method: 'POST',
        sendToken: true,
    })
        .then((data) => {
            dispatch(actions.getCardSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getCardFailAction(error));
        });
};

// DELETE LIST

export const deleteCard = (id) => async (dispatch, getState, service) => {
    dispatch(actions.deleteCardStartAction());
    await service({
        path: 'iyzico/cards/delete',
        method: 'POST',
        sendToken: true,
        body: {
            cardToken: id,
        },
    })
        .then((data) => {
            dispatch(actions.deleteCardSuccessAction(data));
            message.success('Kart Başarı ile Silindi!');
        })
        .catch((error) => {
            dispatch(actions.deleteCardFailAction(error));
            message.error('Bir Hata Oluştu!');
        });
};

// GET IP

export const getIpFromService = () => async (dispatch, getState, service) => {
    dispatch(actions.getIpStartAction());
    await axios
        .get('https://api.ipify.org?format=json', {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((data) => {
            dispatch(actions.getIpSuccessAction(data));
        })
        .catch((error) => {
            dispatch(actions.getIpFailAction(error));
            message.error(' IP GELIRKEN BIR Bir Hata Oluştu!');
        });
};
// CANCEL ORDER
const token = window.localStorage.getItem('token');
export const cancelPacketKobilPay = async (id) => {
    await fetch(`https://apiprep.dongu.com/en/order/${id}/`, {
        method: 'DELETE',
        sendToken: true,
        headers: {
            Authorization: `JWT ${token}`,
            'X-FORWARDED-BY': 'istanbulsenin',
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(() => {
            message.success('Paket Başarı ile Silindi!');
        })
        .catch((err) => message.error('Bir hata oluştu'));
};
// GET PAYMENT INQUIRY

// export const getPaymentInquiry = (ip, conversationId) => async (dispatch, getState, service) => {
//     dispatch(actions.getPaymentInquiryStartAction());

//     await service({
//         path: 'iyzico/payment/inquiry',
//         method: 'POST',
//         sendToken: true,
//         body: {
//             ip,
//             conversationId,
//         },
//     })
//         .then((data) => {
//             dispatch(actions.getPaymentInquirySuccessAction(data));
//         })
//         .catch((error) => {
//             dispatch(actions.getPaymentInquiryFailAction(error));
//         });
// };
