import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
    background-color: white;
    // height: 48px;
    border: 2px solid #f2f2fc;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
`;

export const EnWrapper = styled(Col)`
    justify-content: space-between;
    width: 65%;
    display: flex;
`;
