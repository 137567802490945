import React from 'react';
import { useHistory } from 'react-router';
import { RecycleStyles, RecycleContentWrapper, RecycleTitle, RecycleContent, JoinUsButton, RecycleImage, Empty } from './styles';
import { Title, Paragraph, Text } from '../../../../components/Typography/styles';
import Recycle from '../../../../../Assets/Shared/FoodWaste/recycle.svg';
import useMediaQuery from '../../../../../helpers/Hooks/useMediaQuery';
import Bg from '../../../../../Assets/Shared/FoodWaste/joinus.svg';

const RecycleSection = () => {
    const { sm } = useMediaQuery();
    const history = useHistory();
    return (
        <RecycleStyles
            span={!sm && 24}
            backgroundImage={sm ? '' : Bg}
            style={{
                justifyContent: sm ? 'center' : 'inherit',
            }}>
            <Empty span={10} style={{ display: sm ? 'none' : 'flex' }} />
            <RecycleContentWrapper style={{ width: sm ? '90%' : '100%', justifyContent: sm && 'center' }}>
                <RecycleImage src={Recycle} alt="recyle" style={{ display: sm ? 'flex' : 'none', marginTop: sm ? '-10px' : '' }} />
                <RecycleTitle>
                    <Title
                        weight="bold"
                        style={{ textAlign: sm ? 'center' : 'initial', marginTop: sm ? '-20px' : '25px' }}
                        color="#F38262"
                        size="mediumTitle">
                        Sana İhtiyacımız Var!
                    </Title>
                </RecycleTitle>
                <RecycleContent>
                    <Paragraph color="#000" style={{ textAlign: sm ? 'center' : 'inherit' }} weight="medium" size="description">
                        Gıda israfını en aza indirerek gezegeni birlikte korumak için, seni Döngü’ye davet ediyoruz.
                        <br />
                        <br />
                        Sen de hemen Döngü’ye katılarak, gıda israfı ile mücadele için bir adım atabilirsin.
                    </Paragraph>
                </RecycleContent>
                <JoinUsButton
                    onClick={() => {
                        history.push('isletmeler-icin');
                        window.scrollTo(0, 0);
                    }}
                    type="primary"
                    style={{ justifyContent: sm ? 'center' : 'inherit', width: sm ? '100%' : '212px' }}>
                    <Text color="white" weight="semi-bold">
                        Bize Katıl
                    </Text>
                </JoinUsButton>
            </RecycleContentWrapper>
        </RecycleStyles>
    );
};

export default RecycleSection;
