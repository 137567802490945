import { useEffect, useState } from 'react';

function useKobilPay() {
    const [isKobilPay, setKobilPay] = useState(false);

    useEffect(() => {
        const kobilPayValue = true;
        setKobilPay(kobilPayValue);
    }, []);
    return isKobilPay;
}

export default useKobilPay;
