import React from 'react';
import { useHistory } from 'react-router-dom';
import LeftSection from './LeftSection';
import RightSection from './RightSection/index';
import { Wrapper, DividerStyle, TitleRow, BackBtn } from './styles';
import { Text } from '../../../../components/Typography/styles';

export default function SearcBar(props) {
    const { setKeyword, keyword, searchQuery, query, setSearchQuery, toggleRightSectionDrawer, setFilterCity, storeData } = props;
    const history = useHistory();

    return (
        <Wrapper>
            {/* <TitleRow justify="center" align="middle">
                <BackBtn onClick={() => history.push('/restoran-bul')} />
                <Text size="22px" weight="light" color="smoke">
                    Favorilerim
                </Text>
            </TitleRow> */}
            <DividerStyle />
            <LeftSection setFilterCity={setFilterCity} storeData={storeData} />
            <RightSection
                toggleRightSectionDrawer={toggleRightSectionDrawer}
                keyword={keyword}
                setKeyword={setKeyword}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                query={query}
            />
        </Wrapper>
    );
}
